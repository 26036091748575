import { Component, Input, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { SideMenuService } from '../../components/side-menu/side-menu.service';
import { faBars, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '20rem',
        }),
      ),
      state(
        'closed',
        style({
          width: '6rem',
        }),
      ),
      transition('open => closed', [animate('0.3s ease-out')]),
      transition('closed => open', [animate('0.3s ease-out')]),
    ]),
    trigger('mobileOpenClose', [
      state('open', style({ height: 'auto', opacity: '1', transform: 'translateY(0)' })),
      state(
        'closed',
        style({
          height: '0',
          opacity: '0',
          transform: 'translateY(-800px)',
        }),
      ),
      transition('open => closed', [animate('0.3s ease-out')]),
      transition('closed => open', [animate('0.3s ease-out')]),
    ]),
  ],
})
export class MainLayoutComponent {
  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;
  @Input() breadcrumbs;
  @Input() showHeader = true;
  @Input() showBreadcrumbs = true;

  public menuIcon: IconDefinition = faBars;

  constructor(private _sideMenu: SideMenuService) {}

  get sideMenu() {
    return {
      view: this._sideMenu.view$,
      preferences: this._sideMenu.preferences$,
      canShow: this._sideMenu.canShow$,
    };
  }

  mobileToggleSideMenu() {
    this._sideMenu.toggle();
  }
}
